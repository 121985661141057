*, ::before {
  box-sizing: border-box;
}

html {
  background-color: #121212;
  font-family: Arial, serif;
}

body {
  margin: 0;
  background-color: unset !important;
}

body,
.App,
#root {
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
