.bottom-nav {
  position: sticky;
  bottom: 0;
  width: 100% !important;
  margin-top: auto;
  z-index: 1;
}

.bottom-nav .MuiBottomNavigation-root {
  background-color: #222222;
}

.bottom-nav .MuiBottomNavigation-root button {
  max-width: unset!important;
}

@media (max-width: 220px) {
  .bottom-nav {
    display: none;
  }
}
