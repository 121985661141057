.toolbar {
  position: sticky;
  top: 0;
  z-index: 10;
  flex-grow: unset!important;
}

.toolbar header {
  background-color: #222222;
  background-image: unset;
}

.nav-link {
  font-size: 12px!important;
}
